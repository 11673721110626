//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Page',
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  head() {
    const head = {
      script: [
        {
          defer: true,
          type: 'text/javascript',
          src: this.$config.paypalUrl,
        },
      ],
    }
    return head
  },
}
